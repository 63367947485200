import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import i from 'libs/i18n'
import { originCurrencyUnitFn, sellingCurrencyUnitFn } from 'libs/utils'
import { msToTime } from 'libs/utils'

interface Props {
  broadcastName?: string
  broadcastStateCode?: string
  columnNum?: number
  incomingCounterInfo?: string
  index: number
  representativeProduct?: ProductDetail | null
  shopName?: string
  thumbnail?: string
  totalDuration?: string
  focusKey?: string
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
  onEnterPress?: (props: object, details: KeyPressDetails) => void
  type: string
}

const Card: React.FC<Props> = ({
  broadcastName,
  broadcastStateCode,
  columnNum,
  incomingCounterInfo,
  index,
  focusKey,
  onFocus,
  onEnterPress,
  representativeProduct,
  shopName,
  thumbnail,
  totalDuration,
  type = 'recent',
}) => {
  const {
    productThumbnailUrlList,
    productName,
    sellingPrice,
    priceTypeCode,
    discountRate,
    price,
    isSoldout,
    currencyId,
  } = representativeProduct || {}
  const productImg = productThumbnailUrlList?.filter((x) => x.isRepresentative === true)
  const productImgUrl = productImg && productImg[0]?.thumbnailUrl + '?w=96&h=96'

  const { ref, focused } = useFocusable({
    focusKey,
    onFocus,
    onEnterPress,
  })

  const priceInfoEl = useMemo(() => {
    // 제품이 품절일 경우
    if (isSoldout !== 0) {
      return <div className="product_price">{i.t('player_page.product_soldout')}</div>
    }

    if (priceTypeCode === 'text') {
      return <div className="product_price">{price}</div>
    }

    if (priceTypeCode === 'basic' && discountRate === '0') {
      const stringPrice = price?.toString() ?? ''
      const stringSellingPrice = sellingPrice?.toString() ?? ''

      return (
        <div className="product_price_wrapper">
          <div className="product_price">
            {originCurrencyUnitFn({
              currencyId,
              price:
                typeof sellingPrice !== 'undefined' && stringSellingPrice === '0'
                  ? stringPrice
                  : stringSellingPrice,
            })}
          </div>
        </div>
      )
    }

    if (priceTypeCode === 'basic' && discountRate !== '0') {
      return (
        <div className="product_price_wrapper">
          <div className="product_price">
            {sellingCurrencyUnitFn({ currencyId, sellingPrice: sellingPrice?.toString() ?? '' })}
          </div>
          <div className="product_discount">{discountRate}%</div>
        </div>
      )
    }

    return (
      <div className="product_price">{!price || price === '0' ? <>&nbsp;</> : <>{price}</>}</div>
    )
  }, [discountRate, price, priceTypeCode, sellingPrice, currencyId, isSoldout])

  const productInfoEl = useMemo(() => {
    if (!representativeProduct) return null
    return (
      <div className="product_wrapper">
        <img src={`${productImgUrl}?h=200&w=200&q=60&f=webp`} alt="product_img" />
        <div className="product_info">
          <div className="product_name">{productName}</div>
          {priceInfoEl}
        </div>
      </div>
    )
  }, [priceInfoEl, productImgUrl, productName, representativeProduct])

  const broadcastInfoEl = useMemo(() => {
    return <div className="title">{broadcastName}</div>
  }, [broadcastName])

  const shopInfoEl = useMemo(() => {
    return (
      <div className="shop_wrapper">
        <div className="shop_name">{shopName}</div>
      </div>
    )
  }, [shopName])

  const playTimeEl = useMemo(() => {
    if (broadcastStateCode === 'onair') {
      return <div className="onair_wrapper">ONAIR</div>
    }
    return <div className="play_time_wrapper">{msToTime(totalDuration)}</div>
  }, [broadcastStateCode, totalDuration])

  const viewCountEl = useMemo(() => {
    return (
      <div className="view_count_wrapper">
        <div className="icon" />
        <div className="count">{incomingCounterInfo}</div>
      </div>
    )
  }, [incomingCounterInfo])

  const rankingEl = useMemo(() => {
    return (
      <div className="ranking_wrapper">
        <div className="ranking">{index + 1}</div>
      </div>
    )
  }, [index])

  return (
    <CardWrapper
      ref={ref}
      className={focused ? `styledCard focused` : `styledCard`}
      style={{
        width: columnNum ? `calc(100% / ${columnNum} - 20px)` : `276px`,
      }}
    >
      <div className="card_wrapper" style={{ backgroundImage: `url(${thumbnail})` }}>
        <div className="card_header_wrapper">
          {type === 'topten' ? rankingEl : playTimeEl}
          {viewCountEl}
        </div>
        <div className="card_image" />
      </div>
      <div className="partner_info_wrapper">
        {broadcastInfoEl}
        <div className="card_content_wrapper">{productInfoEl}</div>
        {shopInfoEl}
      </div>
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 50px;

  .card_wrapper {
    width: 340px;
    height: 255px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 50%;
      z-index: 20;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    ::after {
      content: '';
      position: absolute;
      z-index: 20;
      left: 0;
      right: 0;
      bottom: 0;
      height: 60%;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    }

    .card_image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      opacity: 1;
      transition: all ease 2s;
      border-radius: 6px;
      overflow: hidden;
    }

    .card_content_wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 15px;
      z-index: 21;

      .product_wrapper {
        display: flex;
        align-items: center;
        padding: 10px 0;

        img {
          width: 48px;
          height: 48px;
          border-radius: 5px;
        }

        .product_info {
          // width: 192px;
          display: flex;
          flex-direction: column;
          margin-left: 7px;

          .product_price_wrapper {
            display: flex;
            align-items: center;
          }

          .product_name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            line-height: 20px;
            color: ${(props) => props.theme.white};
            font-weight: bold;
          }

          .product_price {
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
            font-weight: bold;
          }

          .product_discount {
            color: ${(props) => props.theme.orange};
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            margin-left: 5px;
          }
        }
      }
    }

    .card_header_wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      padding: 10px 15px;
      z-index: 21;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .play_time_wrapper {
        padding: 0 12px 0 10px;
        height: 24px;
        display: flex;
        align-items: center;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.2);
        color: ${(props) => props.theme.white};
        font-size: 14px;
        font-weight: bold;
      }

      .onair_wrapper {
        padding: 0 12px 0 10px;
        height: 24px;
        display: flex;
        align-items: center;
        border-radius: 12px;
        background-color: ${(props) => props.theme.orange};
        color: ${(props) => props.theme.white};
        font-size: 14px;
        font-weight: bold;
      }

      .view_count_wrapper {
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.white};
        font-size: 12px;
        font-weight: bold;
        padding: 0 12px 0 10px;
        height: 24px;

        .icon {
          background-image: url('icon/ic-View.svg');
          background-size: contain;
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
        }

        .count {
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
          margin-left: 5px;
        }
      }

      .ranking_wrapper {
        margin-top: -30px;
        // paint-order: stroke fill;
        // -webkit-text-stroke-width: 3px;
        // -moz-text-stroke-width: 3px;
        .ranking {
          font-size: 40px;
          color: ${(props) => props.theme.white};
          font-weight: bold;
          font-style: italic;
        }
      }
    }
  }
  &.focused {
    .card_wrapper {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 20px;
        border: 10px solid ${(props) => props.theme.white};
      }
    }
  }

  .partner_info_wrapper {
    width: calc(100% - 370px);
    margin-left: 30px;
    .title {
      font-size: 46px;
      line-height: 56px;
      color: #ffffff;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      word-wrap: break-word;
      margin-bottom: 40px;
    }
    .card_content_wrapper {
      .product_wrapper {
        display: flex;
        align-items: center;
        img {
          width: 80px;
          height: 80px;
          border-radius: 5px;
          margin-right: 16px;
        }
        .product_info {
          width: calc(100% - 80px);
          display: flex;
          flex-direction: column;
          justify-content: center;

          .product_name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 32px;
            line-height: 32px;
            color: ${(props) => props.theme.brownGrey2};
            font-weight: bold;
            padding-bottom: 16px;
          }

          .product_price_wrapper {
            display: flex;
            align-items: center;
          }

          .product_price {
            font-size: 32px;
            line-height: 32px;
            color: #ffffff;
            font-weight: bold;
          }

          .product_discount {
            color: #ff2d55;
            font-size: 32px;
            line-height: 32px;
            font-weight: bold;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .shop_wrapper {
    display: flex;
    align-items: center;
    padding: 5px 0 10px 0;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    .shop_name {
      margin-top: 8px;
      font-size: 32px;
      padding-top: 20px;
      color: ${(props) => props.theme.brownGrey2};
    }
    .date {
      font-size: 14px;
      color: ${(props) => props.theme.brownGrey2};
      margin-left: 5px;
    }
  }
`

export default Card
