import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FocusContext,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import CustomerCenter from 'components/setting/CustomerCenter'
import LanguageSelector from 'components/setting/LanguageSelector'
import getIpAddress from 'libs/api/ip'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

const languages = [
  { originIpValue: 'KR', langValue: 'ko', langText: '한국어' },
  { originIpValue: 'US', langValue: 'en', langText: 'English' },
  { originIpValue: 'JP', langValue: 'ja', langText: '日本語' },
  { originIpValue: 'VN', langValue: 'vi', langText: 'Tiếng Việt' },
  { originIpValue: 'ID', langValue: 'id', langText: 'Indonésia' },
]

interface SettingContainerProps {
  isLanguageSelectorOpen: boolean
  setIsLanguageSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>
  onPressEnter: (props: object, details: KeyPressDetails) => void
}

const SettingContainer: React.FC<SettingContainerProps> = (props) => {
  const { isLanguageSelectorOpen, setIsLanguageSelectorOpen, onPressEnter } = props

  const isShowPolicy = useSelector((state: RootState) => state.interaction.isShowPolicy)

  const { ref, focusKey } = useFocusable({
    focusKey: 'setting',
  })

  const [selectedLanguage, setSelectedLanguage] = useState<{
    originIpValue: string
    langValue: string
    langText: string
  }>({ originIpValue: '', langValue: '', langText: 'Language' }) // 선택된 언어 상태

  // 최초 진입시 언어가 설정되어있지 않다면 api를 통해 설정한다.
  const [ipInfoState, setIpInfoState] = useState({
    ip: '',
    country: '',
  })

  useEffect(() => {
    getIpAddress().then((response) => {
      setIpInfoState(response.data)
    })

    window.localStorage.setItem('regionValue', ipInfoState.country)

    const localStorageLanguage: string | null = window.localStorage.getItem('userSelectedLanguage')
    // 유저가 기존에 설정한 값이 로컬스토리지에 남아있을 경우
    if (localStorageLanguage) {
      const storedLanguageData = languages.find((v) => v.langValue === localStorageLanguage)
      if (storedLanguageData) {
        setSelectedLanguage({ ...storedLanguageData })
        i.changeLanguage(storedLanguageData.langValue)
        return
      }
    }

    // 유저가 기존에 설정한 값이 로컬스토리지에 남아있지 않을 경우
    const userSettingLanguage = window.navigator.language
    const userLocationLanguage:
      | {
          originIpValue: string
          langValue: string
          langText: string
        }
      | undefined = languages.find((v) => v.langValue === userSettingLanguage)

    // 지원하는 언어들 중 검색 결과가 있을 경우
    if (userLocationLanguage) {
      setSelectedLanguage({ ...userLocationLanguage })
      i.changeLanguage(userLocationLanguage.langValue)
      return
    }

    // 지원하는 언어들 중 검색 결과가 없을 경우 기본 언어를 영어로 설정
    setSelectedLanguage({ originIpValue: 'US', langValue: 'en', langText: 'English' })
    i.changeLanguage('en')
  }, [ipInfoState.country])

  return (
    <FocusContext.Provider value={focusKey}>
      <SettingContainerWrap ref={ref} needPaddingTop={isShowPolicy}>
        <LanguageSelector
          languages={languages}
          nowLanguage={selectedLanguage?.langText}
          isSelectorOpen={isLanguageSelectorOpen}
          onEnterPress={onPressEnter}
          setLanguage={setSelectedLanguage}
          setIsSelectorOpen={setIsLanguageSelectorOpen}
        />
        <CustomerCenter onEnterPress={onPressEnter} />
      </SettingContainerWrap>
    </FocusContext.Provider>
  )
}
const SettingContainerWrap = styled.div<{ needPaddingTop: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #fff;
  top: 24px;
  right: 44px;
  z-index: 3;
  ${({ needPaddingTop }) =>
    needPaddingTop &&
    css`
      top: 110px;
    `}
`
export default SettingContainer
